import { pseudo } from '@glitz/core';
import { StyledProps } from '@glitz/react';
import React from 'react';
import { styled, theme } from '../../Theme';
import { Style } from '@glitz/type';

type IKexRadio = StyledProps & {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  label?: string;
  isProductPage?: boolean;
};

function KexRadio({
  isChecked,
  onChange,
  label,
  compose,
  isProductPage = false,
}: IKexRadio) {
  return (
    <Label onChange={() => onChange(!isChecked)} css={compose()}>
      <Input
        type="checkbox"
        checked={isChecked}
        onChange={() => onChange(!isChecked)}
        css={isProductPage ? ProductPageInputStyle : InputRadioStyle}
      />
      {label && (
        <styled.P css={isProductPage ? ProductPageParagraphStyle : {}}>
          {label}
        </styled.P>
      )}
    </Label>
  );
}

const ProductPageInputStyle: Style = {
  display: 'inline-block',
  ':before': {
    content: '" "',
    display: 'inline-block',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: '50%',
    backgroundColor: theme.white,
    marginBottom: theme.spacing(3),
    border: {
      xy: {
        style: 'solid',
        width: theme.spacing(0.25),
        color: theme.paperGray,
      },
    },
  },
  ...pseudo(':checked', {
    ':before': {
      border: {
        xy: {
          style: 'solid',
          width: theme.spacing(1.5),
          color: theme.primary,
        },
      },
    },
  }),
};

const ProductPageParagraphStyle: Style = {
  marginLeft: theme.spacing(2),
};

const Label = styled.label({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
});

const Input = styled.input({
  position: 'relative',
  width: theme.spacing(4),
  height: theme.spacing(4),
  WebkitAppearance: 'none',
  marginRight: theme.spacing(2),
});

const InputRadioStyle: Style = {
  ...pseudo(':checked', {
    ':after': {
      content: '" "',
      position: 'absolute',
      width: theme.spacing(2),
      height: theme.spacing(2),
      top: theme.spacing(1),
      left: theme.spacing(1),
      backgroundColor: theme.black,
      borderRadius: '100%',
    },
  }),
  ':before': {
    position: 'absolute',
    content: '" "',
    width: '100%',
    height: '100%',
    border: {
      xy: {
        style: 'solid',
        width: theme.spacing(0.5),
        color: theme.black,
      },
    },
    borderRadius: '100%',
    left: 0,
    top: 0,
  },
  ':hover': {
    cursor: 'pointer',
  },
};

export default styled(KexRadio);
