import React from 'react';
import { styled } from '../../Theme';

function ArrowRightIcon() {
  return (
    <Svg viewBox="0 0 11 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.791 0a.65.65 0 00-.479.195.64.64 0 00-.195.47.64.64 0 00.195.468l8.79 8.789-8.907 8.906a.65.65 0 00-.195.479.65.65 0 00.195.478.654.654 0 00.713.146.639.639 0 00.225-.146l9.394-9.394a.64.64 0 00.196-.47.64.64 0 00-.196-.468L1.27.195A.65.65 0 00.79 0z"
        fill="#1C1B21"
        fillRule="nonzero"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '12px',
  color: '#484848',
});

export default ArrowRightIcon;
