import React, { useState, useEffect } from 'react';
import { theme } from '../../Theme';
import { GetPrice, UpdateCart } from '../../Cart/Cart';
import SellablePackageModel from '../../KexVariation/Models/SellablePackageModel.interface';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import VariationModel from '../../KexVariation/Models/VariationModel.interface';
import Price from '../../Shared/Price/Price';
import useEncodedCurrentUser from '../../Shared/Hooks/useEncodedCurrentUser';
import { RetailerBlock } from '../RetailerBlock';

import { productPageDataLayer } from '../../utils/dataLayer.lib';
import useMedia from '../../Shared/Hooks/useMedia';
import KexLink from '../../Shared/KexLink/KexLink';
import {
  AddToCartButton,
  AddToCartContainer,
  AttrContainerWrapper,
  DropDownContainer,
  Heading,
  NotificationCircle,
  NotificationText,
  NotificationWrapper,
  PackagingButton,
  PackagingButtonActiveStyle,
  ProductSuggestionButton,
  ProductSuggestionColor,
  ProductSuggestionContainer,
  ProductSuggestionText,
  ProductSuggestionWrapper,
  QntAndAddToCartContainer,
  QntContainer,
  QuantityInput,
  SelectorContainer,
  ShortDescription,
  ShortDescriptionHeader,
  ShortDescriptionInfo,
  ShortMainDescription,
  TransportDropdown,
  VariationContainer,
  VariationHeading,
} from './AttributeArea.style';
import { AttributeAreaProps, CalculatedPrice } from './AttributeArea.types';
import { ProductInfo } from './ProductInfo';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';

function AttributeArea({
  content,
  currentPageVariant,
  updateCurrentPageVariant,
  variationCollection,
  retailerItems,
  retailerListPageUrl,
  mainCategory,
  productInfoTitle,
  productInfo,
  productsCollection,
}: AttributeAreaProps) {
  const id = useEncodedCurrentUser();
  const {
    translations: {
      'productPage/addToCart': addToCartLabel,
      'productPage/packageVariation': packageVariationLabel,
      'productPage/articleNumber': articleNumberLabel,
      'productPage/eanCode': eanCodeLabel,
      'productPage/outOfStockShort': notInStockLabel,
      'productPage/transportTitle': transportDropdownLabel,
      'productPage/variantsTitle': variantsListLabel,
    },
  } = useAppSettingsData();
  const isTablet = useMedia(theme.mediaQuery.mediaMaxLarge);

  const [quantity, setQuantity] = useState<number>(1);
  const [calculatedPrice, setCalculatedPrice] = useState<CalculatedPrice>();
  const [isDataLayerCalled, setIsDataLayerCalled] = useState<boolean>(false);

  const [currentPackageObj, setCurrentPackageObj] = useState<
    SellablePackageModel
  >(
    currentPageVariant?.sellablePackages.find(
      (i: SellablePackageModel) => i.preSelected
    ) ?? {
      packagePrice: '',
      qty: 0,
      key: '',
      description: '',
      unit: '',
      discountedPackagePrice: '',
      amountInPackage: 0,
      preSelected: false,
    }
  );

  const productPrice = calculatedPrice
    ? calculatedPrice.price
    : currentPackageObj.packagePrice;

  const loweredPrice = calculatedPrice
    ? calculatedPrice.discountedPrice
    : currentPackageObj.discountedPackagePrice;

  const { authenticated } = useUserStateData();

  // If content (i.e. product) changes, check if the current variation is still available
  useEffect(() => {
    if (variationCollection.length === 0) return;
    let variation = variationCollection.find(
      x => x.articleNumber === currentPageVariant.articleNumber
    );
    // If not, check if we can find a variation with the same package size
    if (!variation) {
      variation = variationCollection.find(
        x =>
          x.package.amountInPackage ===
          currentPageVariant.package.amountInPackage
      );
      updateCurrentPageVariant(
        variation?.articleNumber ?? variationCollection[0].articleNumber
      );
    }
  }, [content]);

  useEffect(() => {
    if (
      (!loweredPrice || loweredPrice === '0') &&
      (!productPrice || productPrice === '0')
    )
      return;
    if (!isDataLayerCalled) {
      productPageDataLayer(
        false,
        productPrice,
        loweredPrice,
        currentPageVariant,
        mainCategory,
        quantity,
        '',
        currentPackageObj?.packagePrice
      );
      setIsDataLayerCalled(true);
    }
  }, [isDataLayerCalled, loweredPrice, productPrice]);

  useEffect(() => {
    const update = async () => {
      try {
        if (!authenticated) {
          return;
        }
        if (!currentPageVariant?.code) {
          return;
        }
        const price: CalculatedPrice = await GetPrice(
          currentPageVariant?.code,
          currentPackageObj?.qty,
          currentPackageObj?.key,
          quantity,
          id
        );

        setCalculatedPrice(price);
      } catch {}
    };
    update();
  }, [quantity, currentPackageObj]);

  useEffect(() => {
    const pack = currentPageVariant?.sellablePackages.find(i => i.preSelected);
    if (pack) {
      setCurrentPackageObj(pack);
    }
  }, [currentPageVariant]);

  const hasPrice =
    !!currentPackageObj?.packagePrice &&
    currentPackageObj?.packagePrice !== '0';

  function setCurrentVariant(variation: VariationModel) {
    updateCurrentPageVariant(variation.articleNumber);
  }

  function setCurrentPackage(currentPackage: string) {
    const packageItem = currentPageVariant?.sellablePackages.find(element => {
      return element.description === currentPackage;
    });

    packageItem && setCurrentPackageObj(packageItem);
  }

  function addToCart() {
    productPageDataLayer(
      true,
      productPrice,
      loweredPrice,
      currentPageVariant,
      mainCategory,
      quantity,
      '',
      currentPackageObj?.packagePrice
    );
    return UpdateCart(
      currentPageVariant?.code,
      currentPackageObj?.qty,
      currentPackageObj?.key,
      quantity,
      id
    );
  }

  const suggestionsColors = {
    blue: theme.tepeBlue,
    green: theme.tepeGreen,
    grey: theme.tepeGrey,
    orange: theme.tepeOrange,
    red: theme.tepeRed,
    yellow: theme.tepeYellow,
    purple: theme.tepePurple,
    pink: theme.tepePink,
  };

  return (
    <>
      <AttrContainerWrapper>
        <Heading>{content.title}</Heading>
        {isTablet ? (
          <>
            <ProductInfo
              currentPageVariant={currentPageVariant}
              articleNumberLabel={articleNumberLabel}
              eanCodeLabel={eanCodeLabel}
            />
            <ShortDescriptionHeader>{content.category}</ShortDescriptionHeader>
            <ShortDescriptionInfo>
              {content.shortDescription}
            </ShortDescriptionInfo>
            <ShortMainDescription
              title={productInfoTitle}
              info={productInfo}
              isProductPage
            />
          </>
        ) : (
          <>
            {hasPrice ? (
              <ShortDescription
                title={content.category}
                info={content.shortDescription}
                isProductPage
              />
            ) : (
              <>
                <ShortDescriptionHeader>
                  {content.category}
                </ShortDescriptionHeader>
                <ShortDescriptionInfo>
                  {content.shortDescription}
                </ShortDescriptionInfo>
              </>
            )}
            <ProductInfo
              currentPageVariant={currentPageVariant}
              articleNumberLabel={articleNumberLabel}
              eanCodeLabel={eanCodeLabel}
            />
          </>
        )}
        {!!productsCollection?.length && (
          <ProductSuggestionContainer>
            <VariationHeading>{variantsListLabel}</VariationHeading>
            <ProductSuggestionWrapper>
              {productsCollection
                .filter(item => !!item.productCollectionName)
                .map(item => (
                  <KexLink href={item.url} key={+item.code!}>
                    <ProductSuggestionButton>
                      {item?.colorKey && (
                        <ProductSuggestionColor
                          css={{
                            backgroundColor:
                              suggestionsColors[
                                item?.colorKey as keyof typeof suggestionsColors
                              ],
                          }}
                        />
                      )}
                      <ProductSuggestionText>
                        {item.productCollectionName}
                      </ProductSuggestionText>
                    </ProductSuggestionButton>
                  </KexLink>
                ))}
            </ProductSuggestionWrapper>
          </ProductSuggestionContainer>
        )}

        {!!variationCollection?.length && (
          <>
            <VariationHeading>{packageVariationLabel}</VariationHeading>
            <VariationContainer>
              {variationCollection.map((variation, index) => {
                const label = `${variation.package.amountInPackage} ${variation.package.unit}`;
                return (
                  <PackagingButton
                    label={label}
                    isChecked={
                      currentPageVariant?.articleNumber ===
                      variation.articleNumber
                    }
                    onChange={() => setCurrentVariant(variation)}
                    css={
                      currentPageVariant?.articleNumber ===
                      variation.articleNumber
                        ? PackagingButtonActiveStyle
                        : {}
                    }
                    isProductPage
                    key={label.concat('-', variation.articleNumber)}
                  />
                );
              })}
            </VariationContainer>
          </>
        )}
        {!!currentPageVariant?.sellablePackages?.length && (
          <>
            <VariationHeading>{transportDropdownLabel}</VariationHeading>
            <SelectorContainer>
              <DropDownContainer>
                <TransportDropdown
                  values={currentPageVariant?.sellablePackages.map(p => ({
                    icon: p.key,
                    value: p.description,
                    heading: `${p.description} - ${p.amountInPackage} - ${p.unit}`,
                  }))}
                  currentValue={{
                    heading: currentPackageObj.description,
                    subHeading: `${currentPackageObj.amountInPackage} ${currentPackageObj.unit}`,
                    value: currentPackageObj.description,
                  }}
                  setSelectedValue={setCurrentPackage}
                  isProductPageDropdown
                />
              </DropDownContainer>
            </SelectorContainer>
            {calculatedPrice && (
              <Price
                price={productPrice}
                loweredPrice={loweredPrice}
                isProductPage
                isOutOfStock={currentPageVariant?.isOutOfStock}
              />
            )}
          </>
        )}
        {currentPageVariant?.isOutOfStock && (
          <NotificationWrapper>
            <NotificationCircle />
            <NotificationText>{notInStockLabel}</NotificationText>
          </NotificationWrapper>
        )}

        {hasPrice && (
          <>
            <QntAndAddToCartContainer>
              <QntContainer>
                <QuantityInput
                  updateState={q => {
                    setQuantity(q);
                  }}
                  quantity={quantity}
                />
              </QntContainer>
              <AddToCartContainer>
                <AddToCartButton
                  action={addToCart}
                  disabled={currentPageVariant?.isOutOfStock}
                >
                  {addToCartLabel}
                </AddToCartButton>
              </AddToCartContainer>
            </QntAndAddToCartContainer>
          </>
        )}
        {!!retailerItems?.length && (
          <RetailerBlock
            retailerItems={retailerItems}
            retailerListPageUrl={retailerListPageUrl}
          />
        )}
      </AttrContainerWrapper>
    </>
  );
}

export default AttributeArea;
