import React, { useEffect, useState } from 'react';
import IProductPage from './Models/ProductPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { styled, theme } from '../Theme';
import AttributeArea from './AttributeArea/AttributeArea';
import ProductPageSlider from './ProductPageSlider';
import { media } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import Grid from '../Shared/Grid/Grid';
import { GridSize } from '../Enums/GridSize.enum';
import CollapsableSection from './CollapsableSection';
import useMedia from '../Shared/Hooks/useMedia';
import CampaignBanner from './../Shared/Campaign/CampaingBanner';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumb';
import ImageModel from '../Assets/Models/ImageModel.interface';
import ContentArea from './../Shared/ContentArea/ContentArea';

function ProductPage() {
  const {
    translations: { 'productPage/productInformation': productInformationTitle },
  } = useAppSettingsData();

  const productPageData = useCurrentPage<IProductPage>();

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const isTablet = useMedia(theme.mediaQuery.mediaMaxLarge);

  const [currentVariation, setCurrentVariation] = useState<VariationModel>();

  const filterImages = (): ImageModel[] => {
    // Get all generic images for the product
    let images = productPageData.sliderImages.filter(
      image => image.parentCode === productPageData.code
    );

    if (currentVariation) {
      let variantImages = productPageData.sliderImages.filter(
        image => image.parentCode === currentVariation.code
      );

      if (variantImages?.length) {
        // Take the first image of the variants and put it as image #2
        images.splice(1, 0, variantImages.shift()!);
      }
      images = images.concat(variantImages);
    }
    return images;
  };

  const [currentImages, setCurrentImages] = useState<ImageModel[]>(
    filterImages()
  );

  const renderCampaign = () => {
    return (
      !!currentVariation?.promotion?.campaignName && (
        <CampaignBanner
          heading={currentVariation.promotion?.campaignName}
          description={currentVariation.promotion?.campaignDescription}
          isProductPage
        />
      )
    );
  };

  const renderusps = () => {
    return (
      productPageData && (
        <ListItemContainer>
          {productPageData.usPs.map((x, i) => (
            <ListItem key={i}>{x}</ListItem>
          ))}
        </ListItemContainer>
      )
    );
  };

  const renderBasicInfo = () => {
    return (
      <>
        {renderusps()}
        <ProductInfoDescription>
          {productPageData.description}
        </ProductInfoDescription>
      </>
    );
  };

  const renderProductInfo = () => {
    return (
      <ProductInfo>
        {!!currentVariation?.promotion && renderCampaign()}
        {isDesktop ? (
          <>
            <HeadingProductInfo>{productInformationTitle}</HeadingProductInfo>
            {renderBasicInfo()}
          </>
        ) : (
          !isTablet && (
            <CollapsableSection
              title={productInformationTitle}
              info={renderBasicInfo()}
            />
          )
        )}
      </ProductInfo>
    );
  };

  useEffect(() => {
    setCurrentVariation(productPageData.variationCollection[0]);
  }, []);

  useEffect(() => {
    setCurrentImages(filterImages());
  }, [currentVariation]);

  function updateVariant(articleNumber: string) {
    const variation = productPageData.variationCollection.find(
      v => v.articleNumber === articleNumber
    );
    !!variation && setCurrentVariation(variation);
  }

  return (
    <>
      <BreadcrumbsContainer>
        <Breadcrumbs />
      </BreadcrumbsContainer>
      <Grid type={GridSize.Eight} noPadding={isDesktop}>
        <Container>
          <ProductImage>
            {!!currentImages?.length && (
              <ProductPageSlider
                images={currentImages}
                videos={productPageData.videosCollection}
              />
            )}
          </ProductImage>
          {renderProductInfo()}
        </Container>
        <AttributeArea
          content={{
            title: productPageData.displayName,
            category: productPageData.mainCategory,
            shortDescription: productPageData.shortDescription,
          }}
          currentPageVariant={
            currentVariation ?? productPageData.variationCollection[0]
          }
          variationCollection={productPageData.variationCollection}
          retailerListPageUrl={productPageData.retailerListPageUrl}
          retailerItems={productPageData.retailerItems}
          updateCurrentPageVariant={updateVariant}
          mainCategory={productPageData.mainCategory}
          productInfoTitle={productInformationTitle}
          productInfo={renderBasicInfo()}
          productsCollection={productPageData.productsCollection}
        />
      </Grid>
      {Array.isArray(productPageData.contentArea) && (
        <ProductBlocks>
          <Grid type={12} data-content-area>
            <ContentArea childItems={productPageData.contentArea} />
          </Grid>
        </ProductBlocks>
      )}
    </>
  );
}

const ProductBlocks = styled.div({
  maxWidth: theme.contentMaxWidth,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
});
const BreadcrumbsContainer = styled.div({
  maxWidth: '100%',
  gridColumnEnd: 'span 2',
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(-2),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(-4),
    marginLeft: theme.none,
    gridColumnEnd: 'span 5',
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnEnd: 'span 4',
  }),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    margin: { x: theme.spacing(4) },
  }),
});

const ProductInfo = styled.div({
  maxWidth: '100%',
  gridColumnEnd: 'span 2',
  marginBottom: theme.spacing(6),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumnEnd: 'span 5',
    marginBottom: theme.spacing(16),
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnEnd: 'span 4',
  }),
});

const Container = styled.div({
  maxWidth: '100%',
  position: 'relative',
  gridColumnEnd: 'span 2',
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumnEnd: 'span 5',
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnEnd: 'span 4',
  }),
});

const ProductImage = styled.div({
  padding: {
    x: theme.none,
  },
  width: '100%',
});

const HeadingProductInfo = styled.h3({
  color: theme.black,
  font: { size: theme.eta, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.moreWide,
  marginBottom: theme.spacing(8),
  lineHeight: theme.lineHeight.moreTight,
});

const ProductInfoDescription = styled.p({
  fontSize: theme.gamma,
  color: theme.black,
  letterSpacing: theme.letterSpacing.medium,
  lineHeight: theme.lineHeight.normal,
});

const ListItem = styled.li({
  color: theme.black,
  lineHeight: theme.lineHeight.moreSnug,
  fontSize: theme.gamma,
  letterSpacing: theme.letterSpacing.nearMedium,
  ':before': {
    content: '"• "',
    verticalAlign: -theme.spacing(),
    color: theme.blueDark,
    fontWeight: theme.fontWeight.bold,
    display: 'inline-block',
    paddingRight: theme.spacing(2),
    lineHeight: theme.lineHeight.none,
    fontSize: theme.nu,
  },
});

const ListItemContainer = styled.ul({
  marginBottom: theme.spacing(8),
  display: 'flex',
  gap: `${theme.spacing(4)}px`,
  flexDirection: 'column',
});

export default ProductPage;
