import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import ImageModel from '../Assets/Models/ImageModel.interface';
import useMedia from '../Shared/Hooks/useMedia';
import { pseudo, media } from '@glitz/core';
import Modal from '../Shared/Modal/Modal';
import ImageRatio from '../Shared/Image/ImageRatio';
import CurrentStep, { Slide } from '../Shared/Modal/CurrentStep';
import imagePreset from '../Shared/Common/ImagePresets';
import Carousel from 'nuka-carousel';
import renderDotControls from './renderDotControls';
import { ArrowRightIcon } from '../Shared/Svgs';
import ArrowLeftIcon from '../Shared/Svgs/ArrowLeftIcon';
import VideoBlock from '../Blocks/VideoBlock/VideoBlock';
import VideoModel from '../Assets/Models/VideoModel.interface';
import ModalSlide from '../Shared/Modal/Content/ModalSlide';
import { StyleOrStyleArray } from '@glitz/type';

type ProductPageSliderProps = {
  images: ImageModel[];
  videos: VideoModel[];
};

function ProductPageSlider({ images, videos }: ProductPageSliderProps) {
  const [showModal, toggleModal] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const isTablet = useMedia(theme.mediaQuery.mediaMinMedium);
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const slides: Slide[] = [...images, ...videos];

  const toggleModalState = () => {
    toggleModal(!showModal);
  };

  const leftArrowClick = () => {
    selectedSlide > 0 && setSelectedSlide(selectedSlide - 1);
  };

  const rightArrowClick = () => {
    selectedSlide < slides.length - 1 && setSelectedSlide(selectedSlide + 1);
  };

  const carouselConfig = {
    nextButtonStyle: { display: 'none' },
    prevButtonStyle: { display: 'none' },
  };

  const mainImagesWrapperStyle: StyleOrStyleArray = [
    slides.length === 1
      ? { width: '100%', height: isDesktop ? '406px' : 'unset' }
      : { height: isDesktop ? '406px' : 'unset' },
  ];

  const thumbnailImageStyle: StyleOrStyleArray = [
    {
      height: isDesktop ? '406px' : 'unset',
      objectFit: isDesktop ? 'none' : 'contain',
    },
  ];

  const mainImagesSliderStyle: StyleOrStyleArray = [
    { height: isDesktop ? '406px' : 'unset' },
  ];

  return (
    <Root>
      <MainImageDiv>
        {!isMobile ? (
          <>
            <MainImagesWrapper css={mainImagesWrapperStyle}>
              <ImageRatio ratio={theme.ratioDesktop}>
                <ThumbnailImage
                  css={thumbnailImageStyle}
                  src={
                    slides[0] && slides.length > 1
                      ? slides[0].src + imagePreset.productPage.desktopMain
                      : slides[0].src +
                        imagePreset.productPage.desktopMainSingle
                  }
                  onClick={() => {
                    toggleModal(true);
                    setSelectedSlide(0);
                  }}
                />
              </ImageRatio>
            </MainImagesWrapper>
            {slides.length > 1 && (
              <MainImagesWrapper css={mainImagesSliderStyle}>
                <ImageRatio ratio={theme.ratioDesktop}>
                  <ThumbnailImage
                    css={thumbnailImageStyle}
                    src={
                      slides[1] &&
                      slides[1].src + imagePreset.productPage.desktopMain
                    }
                    onClick={() => {
                      toggleModal(true);
                      setSelectedSlide(1);
                    }}
                  />
                </ImageRatio>
              </MainImagesWrapper>
            )}
          </>
        ) : (
          <Carousel
            defaultControlsConfig={carouselConfig}
            renderBottomCenterControls={renderDotControls}
          >
            {slides
              .filter(item => item?.src)
              .map((slide, index) => (
                <MobileImageDiv key={slide?.src ? index + slide.src : index}>
                  <ImageRatio ratio={theme.ratioMobile}>
                    <ThumbnailImage
                      src={
                        isTablet
                          ? slide.src + imagePreset.productPage.tabletMain
                          : slide.src + imagePreset.productPage.mobileMain
                      }
                      onClick={() => {
                        toggleModal(true);
                        setSelectedSlide(index);
                      }}
                    />
                  </ImageRatio>
                </MobileImageDiv>
              ))}
          </Carousel>
        )}
      </MainImageDiv>
      {!isMobile && slides.length > 2 && (
        <CarouselWrapper>
          <Carousel
            slidesToShow={4}
            heightMode={'current'}
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide, currentSlide }) => (
              <SliderButton
                onClick={previousSlide}
                disabled={currentSlide <= 0}
                css={{ transform: 'translateX(-95%)' }}
              >
                <ArrowLeftIcon />
              </SliderButton>
            )}
            renderCenterRightControls={({
              nextSlide,
              currentSlide,
              slideCount,
            }) => (
              <SliderButton
                onClick={nextSlide}
                disabled={currentSlide >= slideCount - 4}
                css={{ transform: 'translateX(60%)' }}
              >
                <ArrowRightIcon />
              </SliderButton>
            )}
          >
            {slides.map(
              (slide, index) =>
                slide?.url && (
                  <styled.Div
                    onClick={() => {
                      toggleModal(true);
                      setSelectedSlide(index);
                    }}
                  >
                    <VideoBlockWrapper
                      content={{
                        contentVideo: {
                          name: slide.name,
                          url: slide.url,
                        },
                      }}
                      isProductPage
                    />
                  </styled.Div>
                )
            )}
            {slides
              .filter((slide, index) => index > 1)
              .map(
                (slide, index) =>
                  slide?.src && (
                    <ImageRatioWrapper
                      key={slide?.src ? index + slide.src : index}
                      css={{}}
                    >
                      <ThumbnailImage
                        css={thumbnailImageDesktopStyle}
                        src={
                          slide.src + imagePreset.productPage.desktopThumbnails
                        }
                        onClick={() => {
                          toggleModal(true);
                          setSelectedSlide(index + 2); // Since we removed the first 2
                        }}
                      />
                    </ImageRatioWrapper>
                  )
              )}
          </Carousel>
        </CarouselWrapper>
      )}
      {showModal && (
        <Modal
          overlay
          toggle={toggleModalState}
          hasCloseButton
          isImageContainer
          leftArrowClick={leftArrowClick}
          rightArrowClick={rightArrowClick}
          imageNumber={slides.length}
        >
          <CurrentStep content={slides} currentStep={selectedSlide} />
          <ModalSlide slide={slides[selectedSlide]} />
        </Modal>
      )}
    </Root>
  );
}

const Root = styled.div({ marginBottom: theme.spacing(8) });

const MobileImageDiv = styled.div({
  width: '100%',
});

const MainImageDiv = styled.div({
  position: 'relative',
  display: 'flex',
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { x: theme.none },
  }),
});

const MainImagesWrapper = styled.div({
  width: '50%',
});
const ImageRatioWrapper = styled.div({
  width: 'auto',
});

const SliderButton = styled.button({
  backgroundColor: 'rgba(0,0,0,0.0)',
  aspectRatio: '1',
  width: '50px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  ':hover': {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  ':disabled': {
    display: 'none',
  },
});

const ThumbnailImage = styled.img({
  position: 'absolute',
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  ...pseudo(':hover', {
    cursor: 'pointer',
  }),
});

const VideoBlockWrapper = styled(VideoBlock, {
  position: 'absolute',
  height: '96px',
  width: '96px',
  backgroundColor: 'rgba(0, 0, 0, 0.60)',
});

const CarouselWrapper = styled.div({
  maxWidth: '456px',
  height: '96px',
  margin: { xy: 'auto' },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    height: 'auto',
  }),
});

const thumbnailImageDesktopStyle: StyleOrStyleArray = {
  width: '96px',
  height: '96px',
  objectFit: 'none',
  borderRadius: '4px',
};

export default ProductPageSlider;
