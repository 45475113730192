import { media } from '@glitz/core';
import { styled, theme } from '../../Theme';
import KexRadio from '../../Shared/KexRadio/KexRadio';
import CollapsableSection from '../CollapsableSection';
import { FeedbackButton } from '../../Shared/Button/Button';
import { Style } from '@glitz/type';
import { QntInput } from '../QuantityInput';
import ValueDropDown from '../../Shared/ValueDropDown/ValueDropDown';
export const NotificationCircle = styled.div({
  height: theme.spacing(3),
  width: theme.spacing(3),
  borderRadius: '50%',
  display: 'inline-block',
  backgroundColor: theme.brightRed,
  marginRight: theme.spacing(2),
});

export const NotificationText = styled.span({});

export const NotificationWrapper = styled.div({
  marginBottom: theme.spacing(8),
});

export const ProductInfoDescription = styled.div({
  marginBottom: theme.spacing(5),
});

export const QntContainer = styled.div({
  flexBasis: '30%',
});

export const SelectorContainer = styled.div({
  display: 'flex',
  marginBottom: theme.spacing(4),
});

export const DropDownContainer = styled.div({
  flexBasis: '100%',
});

export const VariationContainer = styled.div({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: `${theme.spacing(4)}px`,
});

export const AddToCartButton = styled(FeedbackButton, {
  width: '100%',
  flex: { grow: 0, shrink: 1 },
  color: theme.white,
  padding: { y: theme.spacing(4.25) },
  font: {
    size: theme.gamma,
    weight: theme.fontWeight.bold,
  },
  borderRadius: theme.spacing(),
  lineHeight: theme.lineHeight.snug,
  textTransform: 'uppercase',
  ':disabled': {
    backgroundColor: theme.fairGray,
  },
});

export const Heading = styled.h1({
  font: {
    size: theme.mu,
    weight: theme.fontWeight.bold,
  },
  margin: { bottom: theme.spacing(4) },
  lineHeight: theme.lineHeight.mediumTight,
  letterSpacing: theme.letterSpacing.normal,
  color: theme.grayishBlue,
  ...media(theme.mediaQuery.mediaMinMedium, {
    color: theme.black,
  }),
});

export const ShortDescription = styled(CollapsableSection, {
  border: {
    y: {
      color: theme.fairGray,
      style: 'solid',
      width: theme.tiny,
    },
  },
  marginBottom: theme.spacing(5),
});

export const ShortMainDescription = styled(ShortDescription, {
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMaxVerySmall, {
    marginBottom: theme.spacing(4),
  }),
});

export const AddToCartContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: `${theme.spacing(4)}px`,
  justifyContent: 'space-between',
  flexBasis: '70%',
});

export const AttrContainerWrapper = styled.div({
  position: 'sticky',
  gridColumnEnd: 'span 2',
  width: '100%',
  height: 'fit-content',
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    position: 'relative',
    top: 0,
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    padding: { x: theme.none },
    gridColumnEnd: 'span 4',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumnEnd: 'span 3',
    marginBottom: theme.spacing(16),
  }),
});

export const VariationHeading = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.bolder },
  marginBottom: theme.spacing(4),
  color: theme.black,
  lineHeight: theme.lineHeight.snug,
});

export const ProductInfoItemTitle = styled.span({
  font: { size: theme.gamma },
  lineHeight: theme.lineHeight.normal,
  color: theme.black,
});

export const ProductInfoItem = styled.strong({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.normal,
  color: theme.black,
});

export const PackagingButton = styled(KexRadio, {
  border: {
    xy: { width: theme.spacing(0.25), color: theme.fairGray },
    radius: theme.spacing(),
  },
  padding: { xy: theme.spacing(3) },
  font: { size: theme.beta },
  lineHeight: theme.lineHeight.middleRelaxed,
  color: theme.textPrimary,
  letterSpacing: theme.letterSpacing.narrow,
  textTransform: 'capitalize',
  ':hover': {
    backgroundColor: theme.lightBlue,
  },
});

export const ShortDescriptionHeader = styled.span({
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.snug,
  letterSpacing: theme.letterSpacing.moreWide,
  color: theme.black,
  marginTop: theme.spacing(4),
});

export const ShortDescriptionInfo = styled.p({
  marginTop: theme.spacing(4),
  font: { size: theme.beta },
  lineHeight: theme.lineHeight.snug,
  letterSpacing: theme.letterSpacing.normalWide,
  color: theme.black,
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMaxVerySmall, {
    marginBottom: theme.spacing(4),
  }),
});

export const TransportDropdown = styled(ValueDropDown, {
  padding: { y: theme.spacing(), x: theme.spacing(3) },
  border: {
    xy: { width: theme.spacing(0.25), color: theme.fairGray },
    radius: theme.spacing(),
  },
});

export const QntAndAddToCartContainer = styled.div({
  display: 'flex',
  gap: `${theme.spacing(4)}px`,
  marginBottom: theme.spacing(10),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginBottom: theme.spacing(8),
  }),
});

export const QuantityInput = styled(QntInput, {
  flexBasis: '40%',
});

export const ProductSuggestionWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))',
  gridGap: `${theme.spacing(4)}px`,
});

export const ProductSuggestionButton = styled.button({
  border: {
    xy: { width: theme.spacing(0.25), color: theme.fairGray },
    radius: theme.spacing(),
  },
  padding: { xy: theme.spacing(3) },
  font: { size: theme.beta, weight: theme.fontWeight.bolder },
  lineHeight: theme.lineHeight.mediumSnug,
  color: theme.black,
  display: 'flex',
  flexWrap: 'nowrap',
  gap: `${theme.spacing(2)}px`,
  width: '100%',
  ':hover': {
    backgroundColor: theme.lightBlue,
  },
});

export const ProductSuggestionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(4),
});

export const ProductSuggestionText = styled.span({
  whiteSpace: 'nowrap',
});

export const ProductSuggestionColor = styled.div({
  width: theme.spacing(4.5),
  minWidth: theme.spacing(4.5),
  height: theme.spacing(4.5),
  borderRadius: '50%',
});

export const PackagingButtonActiveStyle: Style = {
  backgroundColor: theme.lightBlue,
  border: { xy: { color: theme.blueDark } },
};
