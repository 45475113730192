import { styled } from '@glitz/react';
import React from 'react';
import RetailerItem from '../Retailers/Models/RetailerItem.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import OfflineRetailerIcon from '../Shared/Icons/OfflineRetailerIcon';
import OnlineRetailerIcon from '../Shared/Icons/OnlineRetailerIcon';
import KexLink from '../Shared/KexLink/KexLink';
import { theme } from '../Theme';

type RetailerBlockProps = {
  retailerItems: RetailerItem[];
  retailerListPageUrl?: string;
};

export const RetailerBlock = ({ retailerItems }: RetailerBlockProps) => {
  const {
    translations: {
      'common/ourResellers': ourResellersLabel,
      'common/buyInStore': buyInStoreLabel,
    },
  } = useAppSettingsData();

  const onlineRetailers = retailerItems.filter(
    retailer => retailer.type === 'Online'
  );
  const offlineRetailers = retailerItems.filter(
    retailer => retailer.type === 'Offline'
  );

  return (
    <>
      {!!onlineRetailers?.length && (
        <RetailerContainer>
          <RetailerTop>
            <OnlineRetailerIcon css={{ maxHeight: '100%' }} />
            <RetailerHeader>{ourResellersLabel}</RetailerHeader>
          </RetailerTop>
          <List>
            {onlineRetailers.map((item, index) => (
              <StyledKexLink href={item.url} key={index + item.url}>
                <Img
                  src={item.image ? item.image.href : ''}
                  alt={item.image ? item.image.href : ''}
                />
              </StyledKexLink>
            ))}
          </List>
        </RetailerContainer>
      )}
      {!!offlineRetailers?.length && (
        <RetailerContainer>
          <RetailerTop>
            <OfflineRetailerIcon css={{ maxHeight: '100%' }} />
            <RetailerHeader>{buyInStoreLabel}</RetailerHeader>
          </RetailerTop>
          <List>
            {offlineRetailers.map((item, index) => (
              <StyledKexLink href={item.url} key={index + item.url}>
                <Img
                  src={item.image ? item.image.href : ''}
                  alt={item.image ? item.image.href : ''}
                />
              </StyledKexLink>
            ))}
          </List>
        </RetailerContainer>
      )}
    </>
  );
};

const Img = styled.img({});

const StyledKexLink = styled(KexLink, {
  height: 'auto',
  display: 'flex',
  aspectRatio: '1.625',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingTop: '8px',
  paddingBottom: '8px',
  overflow: 'hidden',
  border: { xy: { width: '1px', style: 'solid', color: '#e6e6e6' } },
});

const RetailerContainer = styled.div({
  marginTop: theme.spacing(6),
});

const RetailerHeader = styled.h4({
  color: theme.black,
  lineHeight: theme.lineHeight.relaxed,
  fontWeight: theme.fontWeight.bold,
});

const RetailerTop = styled.div({
  display: 'flex',
  height: '72px',
  backgroundColor: '#C8E9E9',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '23px',
  paddingBottom: '23px',
  gap: '11px',
});

const List = styled.div({
  display: 'grid',
  flexWrap: 'wrap',
  gridTemplateColumns: 'repeat(auto-fill,minmax(100px,1fr))',
  gridGap: theme.spacing(4),
  padding: { top: theme.spacing(6) },
});
