import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { theme } from '../../Theme';

const MinusToggleIconBlue = ({ compose }: StyledProps) => {
  return (
    <StyledSvg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16699 8.00001C3.16699 7.63182 3.46547 7.33334 3.83366 7.33334H13.167C13.5352 7.33334 13.8337 7.63182 13.8337 8.00001C13.8337 8.3682 13.5352 8.66668 13.167 8.66668H3.83366C3.46547 8.66668 3.16699 8.3682 3.16699 8.00001Z"
        fill="#004C97"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg({
  width: `${theme.spacing(4.25)}px`,
  height: `${theme.spacing(4)}px`,
  objectFit: 'contain',
  fill: theme.blueDark,
});

const Path = styled.path({
  fill: 'inherit',
  fillRule: 'nonzero',
});

export default styled(MinusToggleIconBlue);
