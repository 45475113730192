import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function OfflineRetailerIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <path d="M7 22.5C6.45 22.5 5.97933 22.3043 5.588 21.913C5.196 21.521 5 21.05 5 20.5C5 19.95 5.196 19.479 5.588 19.087C5.97933 18.6957 6.45 18.5 7 18.5C7.55 18.5 8.02067 18.6957 8.412 19.087C8.804 19.479 9 19.95 9 20.5C9 21.05 8.804 21.521 8.412 21.913C8.02067 22.3043 7.55 22.5 7 22.5ZM17 22.5C16.45 22.5 15.9793 22.3043 15.588 21.913C15.196 21.521 15 21.05 15 20.5C15 19.95 15.196 19.479 15.588 19.087C15.9793 18.6957 16.45 18.5 17 18.5C17.55 18.5 18.021 18.6957 18.413 19.087C18.8043 19.479 19 19.95 19 20.5C19 21.05 18.8043 21.521 18.413 21.913C18.021 22.3043 17.55 22.5 17 22.5ZM6.15 6.5L8.55 11.5H15.55L18.3 6.5H6.15ZM5.2 4.5H19.95C20.3333 4.5 20.625 4.67067 20.825 5.012C21.025 5.354 21.0333 5.7 20.85 6.05L17.3 12.45C17.1167 12.7833 16.8707 13.0417 16.562 13.225C16.254 13.4083 15.9167 13.5 15.55 13.5H8.1L7 15.5H19V17.5H7C6.25 17.5 5.68333 17.1707 5.3 16.512C4.91667 15.854 4.9 15.2 5.25 14.55L6.6 12.1L3 4.5H1V2.5H4.25L5.2 4.5Z" />
    </styled.Svg>
  );
}

export default styled(OfflineRetailerIcon);
