import React from 'react';
import { styled } from '../../Theme';

function MenuIcon() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 11">
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M13.5 10c.276 0 .5.224.5.5s-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11zm2-5c.276 0 .5.224.5.5s-.224.5-.5.5H.5C.224 6 0 5.776 0 5.5S.224 5 .5 5h15zm-2-5c.276 0 .5.224.5.5s-.224.5-.5.5h-11C2.224 1 2 .776 2 .5s.224-.5.5-.5h11z"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '16px',
});

export default MenuIcon;
