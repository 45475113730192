import React, { FC, SyntheticEvent, useState } from 'react';
import { styled, theme } from '../../../Theme';
import { media } from '@glitz/core';
import { Slide } from '../CurrentStep';
import {
  VideoWrapperModal,
  StyledVideo,
  IconWrapper,
  StyledPlayIcon,
} from '../../../Blocks/VideoBlock/VideoBlock';

type PropTypes = {
  slide: Slide;
};

const ModalSlide: FC<PropTypes> = ({ slide }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoRef, setVideoRef] = useState();
  const [videoHasLoaded, setVideoHasLoaded] = useState(false);

  const videoControls = (event: SyntheticEvent) => {
    setVideoRef(event.currentTarget);
    setVideoHasLoaded(true);
  };

  const playOrPauseVideo = () => {
    if (videoRef.paused) {
      videoRef.play();
      setIsPlaying(true);
    } else {
      videoRef.pause();
      setIsPlaying(false);
    }
  };

  return (
    <>
      {slide?.src ? (
        <ModalImage src={slide.src} />
      ) : (
        slide?.url && (
          <VideoWrapperModal>
            {videoHasLoaded}
            <StyledVideo
              onLoadedData={e => videoControls(e)}
              muted
              loop
              key={slide.url}
              controls={isPlaying}
            >
              <source src={slide.url} type="video/mp4" />
            </StyledVideo>
            <IconWrapper onClick={() => playOrPauseVideo()}>
              <StyledPlayIcon css={{ display: isPlaying ? 'none' : 'block' }} />
            </IconWrapper>
          </VideoWrapperModal>
        )
      )}
    </>
  );
};

const ModalImage = styled.img({
  width: '100%',
  padding: {
    x: theme.spacing(4),
    y: theme.spacing(8),
  },
  maxHeight: theme.productImageMaxHeight,
  ...media(theme.mediaQuery.mediaMinSmall, {
    width: 'auto',
    height: '100%',
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    padding: {
      y: theme.spacing(8),
      x: theme.spacing(28),
    },
    maxHeight: '100%',
    maxWidth: '100%',
    height: 'auto',
  }),
});

export default ModalSlide;
