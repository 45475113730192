import React from 'react';
import {
  ProductInfoDescription,
  ProductInfoItem,
  ProductInfoItemTitle,
} from './AttributeArea.style';
import { ProductInfoDescriptionProps } from './AttributeArea.types';

export const ProductInfo: React.FC<ProductInfoDescriptionProps> = ({
  currentPageVariant,
  articleNumberLabel,
  eanCodeLabel,
}) => (
  <ProductInfoDescription>
    {currentPageVariant?.articleNumber && (
      <p>
        <ProductInfoItemTitle>
          {articleNumberLabel}
          {': '}
        </ProductInfoItemTitle>
        <ProductInfoItem>{currentPageVariant?.articleNumber}</ProductInfoItem>
      </p>
    )}
    {currentPageVariant?.eanCode && (
      <p>
        <ProductInfoItemTitle>
          {eanCodeLabel}
          {': '}
        </ProductInfoItemTitle>
        <ProductInfoItem>{currentPageVariant?.eanCode}</ProductInfoItem>
      </p>
    )}
  </ProductInfoDescription>
);
