import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { theme } from '../../Theme';

const PlusToggleIconBlue = ({ compose }: StyledProps) => {
  return (
    <StyledSvg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52669 2.66602C8.89488 2.66602 9.19336 2.96449 9.19336 3.33268V7.33268H13.1934C13.5615 7.33268 13.86 7.63116 13.86 7.99935C13.86 8.36754 13.5615 8.66602 13.1934 8.66602H9.19336V12.666C9.19336 13.0342 8.89488 13.3327 8.52669 13.3327C8.1585 13.3327 7.86003 13.0342 7.86003 12.666V8.66602H3.86003C3.49184 8.66602 3.19336 8.36754 3.19336 7.99935C3.19336 7.63116 3.49184 7.33268 3.86003 7.33268H7.86003V3.33268C7.86003 2.96449 8.1585 2.66602 8.52669 2.66602Z"
        fill="#004C97"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg({
  width: `${theme.spacing(4.25)}px`,
  height: `${theme.spacing(4)}px`,
  objectFit: 'contain',
  fill: theme.blueDark,
});

const Path = styled.path({
  fill: 'inherit',
  fillRule: 'nonzero',
});

export default styled(PlusToggleIconBlue);
