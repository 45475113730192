import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function PlayIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <path d="M32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 C14.326888,64 0,49.673112 0,32 C0,14.326888 14.326888,0 32,0 Z M23.4108654,16.0879562 C22.7990494,15.8088618 22.0844287,16.2128768 22.0069148,16.8796855 L22,17 L22,48 L22.0062132,48.1141137 C22.0797751,48.7825455 22.7927345,49.189485 23.4051799,48.9146164 L23.5120645,48.858947 L49.5120645,33.358947 L49.6201195,33.2849747 C50.1266268,32.8885969 50.1266268,32.1114031 49.6201195,31.7150253 L49.5120645,31.641053 L23.5120645,16.141053 L23.4108654,16.0879562 Z M24,18.76 L47.047,32.5 L24,46.239 L24,18.76 Z"></path>
    </styled.Svg>
  );
}

export default styled(PlayIcon);
