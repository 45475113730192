import React from 'react';
import { styled } from '../../Theme';

function CartIcon() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 21">
      <g fill="none" fillRule="evenodd" stroke="#484848">
        <path
          fill="#FFF"
          fillOpacity="0"
          fillRule="nonzero"
          d="M15 4.5L.5 5 1 19.5l14.5-.5L15 4.5z"
          transform="translate(0 1)"
        />
        <path
          strokeLinecap="round"
          d="M4 8V4c0-2.2 1.8-4 4-4s4 1.8 4 4v4"
          transform="translate(0 1)"
        />
      </g>
    </Svg>
  );
}

const Svg = styled.svg({
  width: '16px',
});

export default CartIcon;
