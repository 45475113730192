import React, { useEffect, useState } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { theme } from '../../Theme';

type PropTypes = StyledProps & {
  youtubeCode: string;
};

function YoutubeIframe({ youtubeCode }: PropTypes) {
  const [iframeSrc, setIframeSrc] = useState('');
  const [videoLoaded, setVideoLoaded] = useState();
  const youtubeUrl = `//www.youtube.com/embed/${youtubeCode}?enablejsapi=1&rel=0&autoplay=1&mute=1`;
  useEffect(() => {
    setIframeSrc(youtubeUrl);
  }, [setIframeSrc, youtubeUrl]);

  return (
    <IFrameWrapper>
      {videoLoaded}
      <StyledIframe
        title="video"
        src={iframeSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        onLoad={() => setVideoLoaded(true)}
      />
    </IFrameWrapper>
  );
}

const IFrameWrapper = styled.div({
  position: 'relative',
  width: '100%',
  paddingBottom: '52.25%',
  maxWidth: theme.videoMaxWidth,
  margin: { xy: 'auto' },
});

const StyledIframe = styled.iframe({
  width: '100%',
  height: '100%',
  maxHeight: theme.videoMaxHeight,
  top: theme.none,
  left: theme.none,
  bottom: theme.none,
  right: theme.none,
  position: 'absolute',
});

export default styled(YoutubeIframe);
