import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { theme } from '../../Theme';

type PropType = StyledProps & {
  isOpen?: boolean;
};

const PlusToggleIcon = ({ compose, isOpen = false }: PropType) => {
  let svgStyleArray: StyleOrStyleArray = [isOpen ? isOpenStyle : {}];

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      css={compose()}
    >
      <G css={compose(svgStyleArray)}>
        <Path d="M8 0a.5.5 0 01.5.5v7h7a.5.5 0 010 1h-7v7a.5.5 0 01-1 0v-7h-7a.5.5 0 010-1h7v-7A.5.5 0 018 0z" />
      </G>
    </StyledSvg>
  );
};

const StyledSvg = styled.svg({
  width: theme.spacing(4),
  height: theme.spacing(4),
  objectFit: 'contain',
  fill: theme.secondaryText,
});

const G = styled.g({
  transformOrigin: 'center',
  transition: {
    duration: '200ms',
    property: 'transform',
  },
});

const Path = styled.path({
  fill: 'inherit',
  fillRule: 'nonzero',
});

const isOpenStyle = {
  transform: 'rotate3d(0, 0, 1, 45deg)',
  transformOrigin: 'center',
} as Style;

export default styled(PlusToggleIcon);
