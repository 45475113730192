import React from 'react';
import { styled, theme } from '../../Theme';
import ImageModel from '../../Assets/Models/ImageModel.interface';
import { media } from '@glitz/core';
import VideoModel from '../../Assets/Models/VideoModel.interface';

export type Slide = Partial<ImageModel> & VideoModel;

type CurrentStepType = {
  content: Slide[];
  currentStep: number;
};

function CurrentStep({ content, currentStep }: CurrentStepType) {
  return (
    <>
      {content.length > 1 && (
        <Wrapper>
          {content.map((_, i) => (
            <Bar
              key={i}
              css={
                i === currentStep
                  ? { backgroundColor: theme.black }
                  : { backgroundColor: theme.gray }
              }
            />
          ))}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  bottom: theme.spacing(8),
});

const Bar = styled.div({
  backgroundColor: theme.lightGray,
  width: theme.modalStepBarWidthMobile,
  margin: { x: theme.tiny },
  ...media(theme.mediaQuery.mediaMinMedium, {
    width: theme.modalStepBarWidthDesktop,
    margin: { x: theme.spacing(0.5) },
  }),
  height: theme.spacing(1),
});

export default CurrentStep;
