import React, { useState, useRef } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { theme } from '../Theme';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import { ScrollToEle } from '../Shared/Common/Helpers';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import PlusToggleIconBlue from '../Shared/Icons/PlusToggleIconBlue';
import MinusToggleIconBlue from '../Shared/Icons/MinusToogleIconBlue';

type CollapsableSectionType = StyledProps & {
  title: string;
  children?: string;
  info?: React.ReactNode;
  isProductPage?: boolean;
};

const CollapsableSection = ({
  compose,
  children,
  info,
  title = '',
  isProductPage = false,
}: CollapsableSectionType) => {
  const {
    translations: {
      'common/assortmentReadMore': readMore,
      'common/assortmentReadLess': readLess,
    },
  } = useAppSettingsData();
  const [isOpen, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    if (!isOpen && wrapperRef.current && !isProductPage) {
      ScrollToEle(wrapperRef.current);
    }

    setOpen(!isOpen);
  };

  const innerWrapperStyleArray: StyleOrStyleArray = [
    isOpen
      ? {
          ...innerWrapperAnimationOpen,
          ...ProductPageInnerWrapperStyle,
          height: '100%',
        }
      : {
          ...innerWrapperAnimationClose,
          ...ProductPageInnerWrapperStyle,
          marginTop: 0,
        },
  ];

  const titleStyleArray: StyleOrStyleArray = [
    isProductPage ? ProductPageTitleStyle : {},
  ];

  const titleWrapperStyleArray: StyleOrStyleArray = [
    isProductPage ? ProductPageTitleWrapperStyle : {},
  ];

  const wrapperStyleArray: StyleOrStyleArray = [
    isProductPage ? ProductPageWrapperStyle : {},
  ];

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper css={compose(wrapperStyleArray)} ref={wrapperRef}>
      <TitleWrapper css={titleWrapperStyleArray} onClick={onToggle}>
        <Title css={titleStyleArray}>{title}</Title>
        {isProductPage ? (
          <ToggleButton>
            {isOpen ? (
              <>
                {readLess}
                <MinusToggleIconBlue />
              </>
            ) : (
              <>
                {readMore}
                <PlusToggleIconBlue />
              </>
            )}
          </ToggleButton>
        ) : (
          <PlusToggleIcon isOpen={isOpen} />
        )}
      </TitleWrapper>
      {isProductPage ? (
        <InnerWrapper css={innerWrapperStyleArray}>
          <>
            {info && info}
            {children && (
              <Inner>
                <XhtmlComponent content={children} />
              </Inner>
            )}
          </>
        </InnerWrapper>
      ) : (
        <>
          {isOpen && (
            <InnerWrapper>
              <>
                {info && info}
                {children && (
                  <Inner>
                    <XhtmlComponent content={children} />
                  </Inner>
                )}
              </>
            </InnerWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default styled(CollapsableSection);

const Wrapper = styled.div({
  cursor: 'pointer',
  textAlign: 'left',
  border: {
    bottom: {
      color: theme.gray,
      style: 'solid',
      width: theme.tiny,
    },
  },
  padding: {
    y: theme.spacing(4),
  },
  ':first-child': {
    border: {
      top: {
        color: theme.gray,
        style: 'solid',
        width: theme.tiny,
      },
    },
  },
  ':last-child': {
    border: {
      top: {
        width: theme.none,
      },
    },
  },
  ':only-child': {
    border: {
      top: {
        color: theme.gray,
        style: 'solid',
        width: theme.tiny,
      },
    },
  },
});

const TitleWrapper = styled.div({
  color: theme.grayishBlue,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.lighter,
  lineHeight: theme.lineHeight.relaxed,
  letterSpacing: theme.letterSpacing.medium,
});

const Title = styled.h3({
  margin: {
    right: theme.spacing(2),
  },
});

const innerWrapperAnimationOpen: Style = {
  height: 0,
  animationName: {
    from: {
      opacity: 0,
      transform: 'translateY(-20%)',
      height: '100%',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animationDuration: theme.timings.twoFifths,
};

const innerWrapperAnimationClose: Style = {
  height: 0,
  animationName: {
    from: {
      opacity: 1,
      maxHeight: '100%',
      visibility: 'visible',
    },
    to: {
      opacity: 0,
      transform: 'translateY(-20%)',
      maxHeight: '0%',
      visibility: 'hidden',
    },
  },
  animationDuration: theme.timings.twoFifths,
};

const InnerWrapper = styled.div({
  overflow: 'hidden',
});

const ProductPageTitleStyle: Style = {
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.delta,
  lineHeight: theme.lineHeight.moreTight,
  letterSpacing: theme.letterSpacing.moreWide,
  color: theme.breadText,
};

const ProductPageTitleWrapperStyle: Style = {
  padding: {
    left: theme.spacing(6),
    right: theme.spacing(5),
  },
};

const ProductPageWrapperStyle: Style = {
  backgroundColor: 'rgba(248, 248, 248, 0.36)',
};

const ProductPageInnerWrapperStyle: Style = {
  marginTop: theme.spacing(4),
  padding: {
    left: theme.spacing(6),
    right: theme.spacing(6),
  },
  fontWeight: theme.fontWeight.bolder,
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.moreTight,
  color: theme.black,
};

const Inner = styled.div({
  padding: {
    top: theme.spacing(8),
    bottom: theme.spacing(4),
  },
  lineHeight: theme.lineHeight.normal,
  font: { size: theme.gamma },
  letterSpacing: theme.letterSpacing.wide,
  color: theme.darkGray,
});

const ToggleButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  gap: `${theme.spacing(2)}px`,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.mediumTight,
  letterSpacing: theme.letterSpacing.medium,
  textTransform: 'uppercase',
  color: theme.blueDark,
});
