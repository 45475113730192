import React from 'react';
import { styled } from '../../Theme';

function CloseIcon() {
  return (
    <Svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.743.781a.654.654 0 01.468.195c.13.13.196.29.196.479a.653.653 0 01-.125.397l-.07.081-8.907 8.907 8.79 8.789.07.081a.634.634 0 01.124.387.64.64 0 01-.195.47.65.65 0 01-.478.195.653.653 0 01-.397-.125l-.082-.07-8.8-8.8-8.798 8.8-.068.059a.68.68 0 01-.4.136.654.654 0 01-.47-.196.65.65 0 01-.194-.478c0-.151.041-.284.125-.397l.07-.082 8.906-8.906L.72 1.913l-.07-.08a.634.634 0 01-.125-.388.64.64 0 01.195-.469.65.65 0 01.479-.195c.15 0 .283.042.396.125l.082.07 8.799 8.8 8.799-8.8.068-.06a.68.68 0 01.4-.136z"
        fill="#1C1B21"
        fillRule="nonzero"
      />
    </Svg>
  );
}

const Svg = styled.svg({
  width: '14px',
  color: '#484848',
});

export default CloseIcon;
