import React from 'react';
import { CarouselSlideRenderControlProps } from 'nuka-carousel';

import { theme, styled } from '../Theme';

const renderDotControls = ({
  currentSlide,
  slideCount,
}: CarouselSlideRenderControlProps) => {
  return (
    <Wrapper className="slider-control-bottomcenter" style={{}}>
      <UnorderedList>
        {[...Array(slideCount)].map((_, i) => (
          <Item key={i + 1}>
            <Dots
              css={{
                backgroundColor:
                  currentSlide === i ? theme.blueDark : theme.fairGray,
              }}
            />
          </Item>
        ))}
      </UnorderedList>
    </Wrapper>
  );
};

const Wrapper = styled.div({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: theme.spacing(-3),
});

const UnorderedList = styled.ul({
  position: 'relative',
  display: 'flex',
  gap: `${theme.spacing(2)}px`,
});

const Item = styled.li({
  listStyleType: 'none',
  display: 'inline-block',
});

const Dots = styled.div({
  width: theme.spacing(2),
  height: theme.spacing(2),
  borderRadius: '50%',
  marginRight: theme.tiny,
});

export default renderDotControls;
