import React, { SyntheticEvent, useState } from 'react';
import { StyledProps } from '@glitz/react';

import VideoBlockModel from '../../Blocks/VideoBlock/Models/VideoBlockModel.interface';
import YouTubeIframe from '../../../Features/Shared/YoutubeIframe/YoutubeIframe';
import { styled, theme } from '../../Theme';
import XhtmlComponent from '../../Shared/XhtmlComponent/XhtmlComponent';
import { applyEditModeAttr } from '../../Shared/Common/Helpers';
import { media, pseudo } from '@glitz/core';
import PlayIcon from '../../Shared/Icons/PlayIcon';
import GridItem from '../../Shared/Grid/GridItem';
import Modal from '../../Shared/Modal/Modal';
import ImageRatio from '../../Shared/Image/ImageRatio';
import { Style, StyleOrStyleArray } from '@glitz/type';

type PropType = StyledProps & {
  content: Partial<VideoBlockModel>;
  isProductPage?: boolean;
};

function VideoBlock({
  content: {
    heading,
    youtubeCode,
    contentVideo,
    introText,
    layout,
    bodyText,
    inEditMode,
    videoTop,
    extraMargin,
  },
  isProductPage = false,
  compose,
}: PropType) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoHasLoaded, setVideoHasLoaded] = useState(false);
  const [videoRef, setVideoRef] = useState();
  const [showModal, toggleModal] = useState(false);

  const videoControls = (event: SyntheticEvent) => {
    setVideoRef(event.currentTarget);
    setVideoHasLoaded(true);
  };

  const toggleModalState = () => {
    !isProductPage && toggleModal(!showModal);
  };
  const playOrPauseVideo = () => {
    if (videoRef.paused) {
      videoRef.play();
      setIsPlaying(true);
    } else {
      videoRef.pause();
      setIsPlaying(false);
    }
  };

  const renderContent = () => {
    return (
      <HeadingContainer data-blocklayout={videoTop}>
        {introText && (
          <IntroText {...applyEditModeAttr(!!inEditMode && 'IntroText')}>
            {introText}
          </IntroText>
        )}
        {heading && (
          <Heading {...applyEditModeAttr(!!inEditMode && 'Heading')}>
            {heading}
          </Heading>
        )}
        {bodyText && (
          <Description>
            <XhtmlComponent
              {...applyEditModeAttr(!!inEditMode && 'BodyText')}
              content={bodyText}
              className="block-text"
            />
          </Description>
        )}
      </HeadingContainer>
    );
  };

  const containerStyle: StyleOrStyleArray = [
    isProductPage ? VideoBlockContainerProductPageStyle : {},
  ];

  const videoStyle: StyleOrStyleArray = [
    isProductPage ? { height: '100%', borderRadius: '4px' } : {},
  ];

  return (
    <GridItem
      layout={layout}
      extraMargin={extraMargin}
      compose={() => compose?.(videoStyle)}
    >
      {youtubeCode && <VideoFrame /> ? (
        <VideoBlockRoot>
          <VideoBlockContainer data-blocklayout={videoTop} css={containerStyle}>
            {renderContent()}
            <VideoFrame>
              <ImageRatio
                src={`https://img.youtube.com/vi/${youtubeCode}/maxresdefault.jpg`}
              />
              <IconWrapper onClick={toggleModalState}>
                <StyledPlayIcon />
              </IconWrapper>
            </VideoFrame>
          </VideoBlockContainer>
          {showModal && (
            <Modal overlay toggle={toggleModalState} hasCloseButton>
              <VideoWrapperModelYouTube>
                <StyledYoutubeIframe youtubeCode={youtubeCode} />
              </VideoWrapperModelYouTube>
            </Modal>
          )}
        </VideoBlockRoot>
      ) : (
        <VideoBlockRoot css={videoStyle}>
          <VideoBlockContainer data-blocklayout={videoTop} css={containerStyle}>
            {!isProductPage && renderContent()}
            <styled.Div css={isProductPage ? WrapperProductPageStyle : {}}>
              <VideoWrapper css={videoStyle}>
                {videoHasLoaded}
                <StyledVideo
                  css={isProductPage ? StyledVideoProductPageStyle : {}}
                >
                  <source src={contentVideo?.url} type="video/mp4" />
                </StyledVideo>
                <IconWrapper onClick={toggleModalState}>
                  <StyledPlayIcon />
                </IconWrapper>
              </VideoWrapper>
            </styled.Div>
          </VideoBlockContainer>
          {showModal && (
            <Modal overlay toggle={toggleModalState}>
              <VideoWrapperModal>
                {videoHasLoaded}
                <StyledVideo
                  onLoadedData={e => videoControls(e)}
                  muted
                  loop
                  key={contentVideo?.url}
                  controls={isPlaying}
                >
                  <source src={contentVideo?.url} type="video/mp4" />
                </StyledVideo>
                <IconWrapper onClick={() => playOrPauseVideo()}>
                  <StyledPlayIcon
                    css={{ display: isPlaying ? 'none' : 'block' }}
                  />
                </IconWrapper>
              </VideoWrapperModal>
            </Modal>
          )}
        </VideoBlockRoot>
      )}
    </GridItem>
  );
}

export default VideoBlock;

const StyledYoutubeIframe = styled(YouTubeIframe, {});

const Heading = styled.h3({
  fontSize: theme.eta,
  fontWeight: theme.fontWeight.bold,
  color: theme.blueDark,
  letterSpacing: theme.letterSpacing.moreWide,
  lineHeight: theme.lineHeight.moreRelaxed,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    fontSize: theme.epsilon,
    lineHeight: theme.lineHeight.relaxed,
  }),
});

const IntroText = styled.p({
  fontSize: theme.alpha,
  marginBottom: theme.spacing(1),
  letterSpacing: theme.letterSpacing.medium,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    lineHeight: theme.lineHeight.relaxed,
  }),
});

const Description = styled.div({
  marginBottom: theme.spacing(4),
});

export const IconWrapper = styled.div({
  ':hover': {
    cursor: 'pointer',
  },
  position: 'absolute',
  left: `calc(50% - ${theme.spacing(8)}px)`,
  top: `calc(50% - ${theme.spacing(8)}px)`,
  ...media(theme.mediaQuery.mediaMaxSmall, {
    left: `calc(50% - ${theme.spacing(6)}px)`,
    top: `calc(50% - ${theme.spacing(6)}px)`,
  }),
});

export const StyledVideo = styled.video({
  width: '100%',
  height: '100%',
  top: theme.none,
  left: theme.none,
  bottom: theme.none,
  right: theme.none,
  position: 'absolute',
});

const VideoBlockRoot = styled.div({
  maxWidth: '100%',
  margin: { x: 'auto' },
  padding: { x: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { xy: theme.none },
  }),
});

const VideoBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  margin: { x: 'auto' },
  padding: { x: 0, y: theme.spacing(3) },
  ...pseudo([':nth-child(n)[data-blocklayout=true]'], {
    flexDirection: 'column-reverse',
  }),
});

const HeadingContainer = styled.div({
  maxWidth: theme.contentMaxWidth,
  width: '100%',
  margin: { xy: 'auto' },
  ...pseudo([':nth-child(n)[data-blocklayout=true]'], {
    marginTop: theme.spacing(4),
    padding: { xy: 0 },
    ...media(theme.mediaQuery.mediaMinLarge, {
      marginTop: theme.spacing(8),
    }),
  }),
});

const VideoWrapper = styled.div({
  position: 'relative',
  width: '100%',
  paddingBottom: '56.25%',
});

export const VideoWrapperModal = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  maxWidth: theme.videoMaxWidth,
  maxHeight: theme.videoMaxHeight,
  ...media(theme.mediaQuery.mediaMaxMedium, {
    paddingBottom: theme.ratioDesktop,
  }),
});

const VideoWrapperModelYouTube = styled.div({
  position: 'relative',
  width: '100%',
  maxWidth: theme.videoMaxWidth,
  maxHeight: theme.videoMaxHeight,
});

export const StyledPlayIcon = styled(PlayIcon, {
  height: theme.spacing(16),
  width: theme.spacing(16),
  position: 'absolute',
  fill: theme.blueDark,
  ...media(theme.mediaQuery.mediaMaxSmall, {
    height: theme.spacing(12),
    width: theme.spacing(12),
  }),
});

const VideoFrame = styled.div({
  position: 'relative',
  width: '100%',
});

const VideoBlockContainerProductPageStyle: Style = {
  padding: { y: 0 },
  height: '96px',
};

const StyledVideoProductPageStyle: Style = {
  objectFit: 'cover',
  height: '100%',
  borderRadius: '4px',
};

const WrapperProductPageStyle: Style = {
  height: '100%',
  borderRadius: '4px',
};
